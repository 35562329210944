import { Typography } from "@mui/material";
import useCurrencyFormatter from "helpers/hooks/useCurrencyFormatter";
import { IInvoiceDetail } from "models/IOrder";

interface IProps {
    invoiceDetail: IInvoiceDetail,
    currencyCulture: string,
    variant: any,
}

const InvoiceDetailPrice: React.FC<IProps> = ({ invoiceDetail, currencyCulture, variant }: IProps) => {
    const cf = useCurrencyFormatter(currencyCulture);

    return <Typography variant={variant} textAlign="right">
        {cf.Format(invoiceDetail.price)}
    </Typography>;
};

export default InvoiceDetailPrice;