import { useCallback } from "react";
import { Typography } from "@mui/material";
import useFormatHelper from "helpers/hooks/useFormatHelper";
import { IInvoiceDetail } from "models/IOrder";

interface IProps {
    invoiceDetail: IInvoiceDetail,
    engineeringUnitSetID: number,
    variant: any,
}

const InvoiceDetailSize: React.FC<IProps> = ({ invoiceDetail, engineeringUnitSetID, variant }: IProps) => {
    
    const formatMethods = useFormatHelper();
    
    const formatLineItemDimensions = useCallback((invoiceDetail: IInvoiceDetail) => {
        return formatMethods.FormatDimensions(invoiceDetail.width, invoiceDetail.height, invoiceDetail.thickness, engineeringUnitSetID);
    }, [formatMethods, engineeringUnitSetID]);

    return <Typography variant={variant} textAlign="left">
        {invoiceDetail.callSize ? invoiceDetail.callSize : formatLineItemDimensions(invoiceDetail)}
    </Typography>;
};

export default InvoiceDetailSize;