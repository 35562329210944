import InvoiceDetailLineItem from "../Fields/InvoiceDetailLineItem";
import InvoiceDetailColumn, { IInvoiceLevelProps } from "./InvoiceDetailColumn";
import { IInvoiceDetail } from "models/IOrder";

export default class InvoiceDetailLineItemColumn extends InvoiceDetailColumn {

    public constructor(columnName: string, headerText: string, width: string) {
        super(columnName, headerText, width);
        this.align = "center";
    }

    public CellContent(props: IInvoiceLevelProps, invoiceDetail: IInvoiceDetail): JSX.Element {

        return <InvoiceDetailLineItem
            invoiceDetail={invoiceDetail}
            variant="body2"
        />
    }

    public FooterContent(invoiceProps: IInvoiceLevelProps): JSX.Element {
        return <></>;
    }
}
