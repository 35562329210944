import { TableRow, TableCell, styled } from "@mui/material"
import { tableCellClasses } from '@mui/material/TableCell';
import InvoiceDetailColumn, { IInvoiceLevelProps } from "../Columns/InvoiceDetailColumn";

interface IProps {
    invoiceLevelProps: IInvoiceLevelProps;
    columns: InvoiceDetailColumn[];
}

const InvoiceDetailFooter: React.FC<IProps> = ({ invoiceLevelProps, columns }: IProps) => {

    const StyledFooterCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.body}`]: {
            paddingLeft: theme.spacing(0.5),
            paddingRight: theme.spacing(0.5),
        },
    }));

    return <>
        <TableRow>
            {columns.map(idc => (
                <StyledFooterCell key={idc.columnName} rowSpan={idc.rowSpan} align={idc.align}>
                    {idc.FooterContent(invoiceLevelProps)}
                </StyledFooterCell>
            ))}
        </TableRow>
    </>;
}


export default InvoiceDetailFooter;