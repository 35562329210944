import { InvoiceTypeEnum } from "helpers/enums";
import { MeasurementSystemEnum } from "helpers/fv.format";
import { IInvoiceDetail } from "models/IOrder";

export interface IInvoiceLevelProps {
    invoiceType: InvoiceTypeEnum,
    engineeringUnitSetID: MeasurementSystemEnum,
    currencyCulture: string,
    subTotal: number,
    taxAmount: number
}

export default abstract class InvoiceDetailColumn {

    columnName: string;
    headerText: string;
    width: string;
    rowSpan: number = 1;
    align?: "inherit" | "left" | "center" | "right" | "justify";

    public constructor(columnName: string, headerText: string, width: string) {
        this.columnName = columnName;
        this.headerText = headerText;
        this.width = width;
    }

    public abstract CellContent(invoiceProps: IInvoiceLevelProps, invoiceDetail: IInvoiceDetail): JSX.Element

    public abstract FooterContent(invoiceProps: IInvoiceLevelProps): JSX.Element

}


