import React from 'react';
import { Dialog, DialogContent } from "@mui/material";

import useIsMobile from "helpers/hooks/useIsMobile";

import InvoiceWrapper from "./InvoiceWrapper";
import { IInvoice } from "helpers/interfaces";

interface IProps {
    dialogVisible: boolean;
    onClose: () => void;
    invoices: IInvoice[];
}

const InvoicesDialog: React.FC<IProps> = ({ dialogVisible, onClose, invoices }: IProps) => {

    const isMobile = useIsMobile();

    return <>
        <Dialog
            open={dialogVisible}
            onClose={onClose}
            fullWidth={isMobile}
            maxWidth={isMobile ? false : undefined}
        >
            <DialogContent sx={{ padding: 1 }}>
                <InvoiceWrapper invoices={invoices} />
            </DialogContent>
        </Dialog>
    </>;

}

export default InvoicesDialog;