import { useTranslations } from "@fenetech/translations";
import { Grid, Paper, Stack, Table, TableBody, TableContainer, Typography, alpha, useTheme } from "@mui/material";
import Format from "helpers/fv.format";
import { IInvoice } from "helpers/interfaces";
import useOrderData from "../useOrderData";
import { useCallback, useMemo } from "react";
import InvoiceDetailColumn, { IInvoiceLevelProps } from "./Columns/InvoiceDetailColumn";
import InvoiceDetailLineItemColumn from "./Columns/InvoiceDetailLineItemColumn";
import InvoiceDetailQuantityColumn from "./Columns/InvoiceDetailQuantityColumn";
import InvoiceDetailPartColumn from "./Columns/InvoiceDetailPartColumn";
import InvoiceDetailSizeColumn from "./Columns/InvoiceDetailSizeColumn";
import InvoiceDetailPriceColumn from "./Columns/InvoiceDetailPriceColumn";
import InvoiceDetailHeader from "./Rows/InvoiceDetailHeader";
import InvoiceDetail from "./Rows/InvoiceDetail";
import InvoiceDetailFooter from "./Rows/InvoiceDetailFooter";
import { InvoiceTypeEnum } from "helpers/enums";

interface IProps {
    invoice: IInvoice
}

function Invoice({ invoice }: IProps) {
    const tm = useTranslations();
    const theme = useTheme();
    const { order } = useOrderData();

    const invoiceIsCancellation = useMemo(() => {
        return invoice.invoiceType === InvoiceTypeEnum.Cancellation || invoice.invoiceType === InvoiceTypeEnum.DepositCancellation || invoice.invoiceType === InvoiceTypeEnum.CreditNoteCancellation;
    }, [invoice]);

    const invoiceIsCreditNote = useMemo(() => {
        return invoice.invoiceType === InvoiceTypeEnum.CreditNote || invoice.invoiceType === InvoiceTypeEnum.CreditNoteCancellation;
    }, [invoice]);

    const columns = useMemo(() => {
        let cols: InvoiceDetailColumn[] = [];

        cols.push(new InvoiceDetailLineItemColumn("colItem", tm.Get("Item"), "50px"));
        cols.push(new InvoiceDetailQuantityColumn("colQty", invoiceIsCreditNote ? "" : tm.Get("Qty"), ""));
        cols.push(new InvoiceDetailPartColumn("colPart", tm.Get("Part"), "50%"));
        cols.push(new InvoiceDetailSizeColumn("colSize", tm.Get("Size"), "20%"));
        cols.push(new InvoiceDetailPriceColumn("colPrice", tm.Get("Price"), "10%"));
        return cols;
    }, [invoiceIsCreditNote, tm]);

    const invoiceLevelProps = useMemo(() => {
        return {
            invoiceType: invoice.invoiceType,
            engineeringUnitSetID: invoice.engineeringUnitSetID,
            currencyCulture: order?.currencyCulture,
            subTotal: invoice.subTotal,
            taxAmount: invoice.taxAmount
        } as IInvoiceLevelProps
    }, [invoice, order]);

    const getInvoiceTypeText = useCallback((invoiceType: InvoiceTypeEnum) => {
        switch(invoiceType) {
            case InvoiceTypeEnum.Standard:
                return "Invoice";
            case InvoiceTypeEnum.Deposit:
                return "Deposit";
            case InvoiceTypeEnum.Transfer:
                return "Transfer";
            case InvoiceTypeEnum.Cancellation:
                return "Cancellation";
            case InvoiceTypeEnum.CreditNote:
                return "Credit Note";
            case InvoiceTypeEnum.DepositCancellation:
                return "Deposit Canellation";
            case InvoiceTypeEnum.CreditNoteCancellation: 
                return "Credit Note Cancellation";
        }
    }, []);

    return <Paper>
        <Grid container spacing={0} p={1} sx={{backgroundColor: invoiceIsCancellation ? alpha(theme.palette.error.light, 0.1) : undefined}}>
            <Grid item xs={12}>
                <Stack spacing={1} direction="row">
                    <Stack spacing={0} direction="column">
                        <Typography fontWeight="bold" variant="body2" noWrap>{tm.Get("Invoice Number:")}</Typography>
                        <Typography fontWeight="bold" variant="body2" noWrap>{tm.Get("Invoice Type:")}</Typography>
                        <Typography fontWeight="bold" variant="body2" noWrap>{tm.Get("Invoice Date:")}</Typography>
                        <Typography fontWeight="bold" variant="body2" noWrap>{tm.Get("Posted:")}</Typography>
                    </Stack>
                    <Stack spacing={0} direction="column">
                        <Typography variant="body2" noWrap>{invoice.invoiceNumber}</Typography>
                        <Typography variant="body2" noWrap>{tm.Get(getInvoiceTypeText(invoice.invoiceType))}</Typography>
                        <Typography variant="body2" noWrap>{Format.FormatDate(invoice.invoiceDate)}</Typography>
                        <Typography variant="body2" noWrap>{invoice.posted ? tm.Get("Yes") : tm.Get("No")}</Typography>
                    </Stack>
                </Stack>
            </Grid>

            <Grid item xs={12}>
                <TableContainer>
                    <Table sx={{ width: "100%", tableLayout: "auto" }} size="small" padding="none">
                        <InvoiceDetailHeader invoiceLevelProps={invoiceLevelProps} columns={columns} />
                        <TableBody>
                            {invoice.invoiceDetails.map(id => <InvoiceDetail key={id.lineItem} invoiceLevelProps={invoiceLevelProps} columns={columns} invoiceDetail={id} />)}
                            <InvoiceDetailFooter invoiceLevelProps={invoiceLevelProps} columns={columns} />
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
        </Grid>
    </Paper>
}

export default Invoice;