import { Typography } from "@mui/material";
import { InvoiceTypeEnum } from "helpers/enums";
import { IInvoiceDetail } from "models/IOrder";
import { useMemo } from "react";

interface IProps {
    invoiceDetail: IInvoiceDetail,
    invoiceType: number,
    variant: any,
}

const InvoiceDetailQuantity: React.FC<IProps> = ({ invoiceDetail, invoiceType, variant }: IProps) => {

    const invoiceIsCreditNote = useMemo(() => {
        return invoiceType === InvoiceTypeEnum.CreditNote || invoiceType === InvoiceTypeEnum.CreditNoteCancellation;
    }, [invoiceType]);

    return <Typography variant={variant} textAlign="center">
        {invoiceIsCreditNote ? "" : invoiceDetail.quantity}
    </Typography>;
};

export default InvoiceDetailQuantity;