import InvoiceDetailPrice from "../Fields/InvoiceDetailPrice";
import InvoiceTotals from "../Fields/InvoiceTotals";
import InvoiceDetailColumn, { IInvoiceLevelProps } from "./InvoiceDetailColumn";
import { IInvoiceDetail } from "models/IOrder";

export default class InvoiceDetailPriceColumn extends InvoiceDetailColumn {

    public constructor(columnName: string, headerText: string, width: string) {
        super(columnName, headerText, width);
        this.align = "right";
    }

    public CellContent(props: IInvoiceLevelProps, invoiceDetail: IInvoiceDetail): JSX.Element {

        return <InvoiceDetailPrice
            invoiceDetail={invoiceDetail}
            currencyCulture={props.currencyCulture}
            variant="body2"
        />
    }

    public FooterContent(invoiceProps: IInvoiceLevelProps): JSX.Element {
        return <InvoiceTotals
            invoiceLevelProps={invoiceProps}
            variant="body2"
        />;
    }
}
