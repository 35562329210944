import { TableRow, TableCell, styled, alpha } from "@mui/material"
import { tableCellClasses } from '@mui/material/TableCell';

import InvoiceDetailColumn, { IInvoiceLevelProps } from "../Columns/InvoiceDetailColumn";
import { IInvoiceDetail } from "models/IOrder";
import { useMemo } from "react";
import { InvoiceTypeEnum } from "helpers/enums";

interface IProps {
    invoiceLevelProps: IInvoiceLevelProps,
    columns: InvoiceDetailColumn[];
    invoiceDetail: IInvoiceDetail,
}

const InvoiceDetail: React.FC<IProps> = ({ invoiceLevelProps, columns, invoiceDetail }: IProps) => {

    const invoiceIsCancellation = useMemo(() => {
        return invoiceLevelProps.invoiceType === InvoiceTypeEnum.Cancellation || invoiceLevelProps.invoiceType === InvoiceTypeEnum.DepositCancellation || invoiceLevelProps.invoiceType === InvoiceTypeEnum.CreditNoteCancellation;
    }, [invoiceLevelProps]);

    const StyledCell = useMemo(() => {
        return styled(TableCell)(({ theme }) => ({
            [`&.${tableCellClasses.body}`]: {
                paddingLeft: theme.spacing(0.5),
                paddingRight: theme.spacing(0.5),
            },
        }));
    }, []); 

    const StyledRow = useMemo(() => {
        if (invoiceIsCancellation) {
            return styled(TableRow)(({ theme }) => ({
                [`&:nth-of-type(even)`]: {
                    backgroundColor: alpha(theme.palette.error.light, 0.2),
                },
                [`&:nth-of-type(odd)`]: {
                    backgroundColor: alpha(theme.palette.error.light, 0.1),
                }
            }));
        } else {
            return styled(TableRow)(({ theme }) => ({
                [`&:nth-of-type(even)`]: {
                    backgroundColor: alpha(theme.palette.secondary.light, 0.1),
                }
            }));
        }
    }, [invoiceIsCancellation]);

    return <>
        <StyledRow>
            {columns.map(idc => (
                <StyledCell key={idc.columnName} rowSpan={idc.rowSpan} align={idc.align}>
                    {idc.CellContent(invoiceLevelProps, invoiceDetail)}
                </StyledCell>
            ))}
        </StyledRow>
    </>;
}

export default InvoiceDetail;