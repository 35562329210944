import { Typography } from "@mui/material";
import { IInvoiceDetail } from "models/IOrder";

interface IProps {
    invoiceDetail: IInvoiceDetail,
    variant: any,
}

const InvoiceDetailLineItem: React.FC<IProps> = ({ invoiceDetail, variant }: IProps) => {
    return <Typography variant={variant} textAlign="center">
        {invoiceDetail.lineItem}
    </Typography>;
};

export default InvoiceDetailLineItem;