import InvoiceDetailSize from "../Fields/InvoiceDetailSize";
import InvoiceTotalLabels from "../Fields/InvoiceTotalLabels";
import InvoiceDetailColumn, { IInvoiceLevelProps } from "./InvoiceDetailColumn";
import { IInvoiceDetail } from "models/IOrder";

export default class InvoiceDetailSizeColumn extends InvoiceDetailColumn {

    public constructor(columnName: string, headerText: string, width: string) {
        super(columnName, headerText, width);
        this.align = "left";
    }

    public CellContent(props: IInvoiceLevelProps, invoiceDetail: IInvoiceDetail): JSX.Element {

        return <InvoiceDetailSize
            invoiceDetail={invoiceDetail}
            engineeringUnitSetID={props.engineeringUnitSetID}
            variant="body2"
        />
    }

    public FooterContent(invoiceProps: IInvoiceLevelProps): JSX.Element {
        return <InvoiceTotalLabels
            variant="body2"
        />;
    }
}
