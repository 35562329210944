import { Stack, Typography } from "@mui/material";
import { useTranslations } from "@fenetech/translations";

interface IProps {
    variant: any
}

const InvoiceTotalLabels: React.FC<IProps> = ({ variant }: IProps) => {

    const tm = useTranslations();

    return <>
        <Stack direction="column">
            <Typography variant={variant} textAlign="right" fontWeight="bold" noWrap >
                {tm.Get("Subtotal:")}
            </Typography>
            <Typography variant={variant} textAlign="right" fontWeight="bold" noWrap >
                {tm.Get("Tax:")}
            </Typography>
            <Typography variant={variant} textAlign="right" fontWeight="bold" noWrap >
                {tm.Get("Total:")}
            </Typography>
        </Stack>
    </>;

};

export default InvoiceTotalLabels;