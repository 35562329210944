import { Stack, Typography } from "@mui/material";
import useCurrencyFormatter from "helpers/hooks/useCurrencyFormatter";
import { IInvoiceLevelProps } from "../Columns/InvoiceDetailColumn";

interface IProps {
    invoiceLevelProps: IInvoiceLevelProps
    variant: any,
}

const InvoiceTotals: React.FC<IProps> = ({ invoiceLevelProps, variant }: IProps) => {

    const currencyFormatter = useCurrencyFormatter(invoiceLevelProps.currencyCulture);

    return <>
        <Stack direction="column">
            <Typography variant={variant} textAlign="right" noWrap >
                {currencyFormatter.Format(invoiceLevelProps.subTotal)}
            </Typography>
            <Typography variant={variant} textAlign="right" noWrap >
                {currencyFormatter.Format(invoiceLevelProps.taxAmount)}
            </Typography>
            <Typography variant={variant} textAlign="right" noWrap >
                {currencyFormatter.Format(invoiceLevelProps.subTotal + invoiceLevelProps.taxAmount)}
            </Typography>
        </Stack>
    </>;

};

export default InvoiceTotals;