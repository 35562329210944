import { useMemo } from "react";
import { Stack, Typography, TypographyProps, Box, Skeleton, useTheme, alpha, Link } from "@mui/material";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';

interface IProps {
    label: any,
    text: any,
    isLoading?: boolean,
    isChecked?: boolean,
    showSkeletonWhenEmpty?: boolean,
    labelProps?: TypographyProps<typeof Typography>,
    textProps?: TypographyProps<typeof Typography>,
    textAction?: () => void,
};

function LabeledText({ label, text, isChecked, showSkeletonWhenEmpty, isLoading, labelProps, textProps, textAction }: IProps) {

    const theme = useTheme();
    const inputLabelColor = useMemo(() => {
        return alpha(theme.palette.text.disabled, 0.6);
    }, [theme]);

    if (isChecked !== undefined) {
        return <Stack direction="row" spacing={1}>
            <Typography {...textProps}>{label}</Typography>
            <Typography {...textProps}>{isChecked ? <CheckCircleIcon color="success" /> : <CancelIcon color="error" />}</Typography>
        </Stack>
    }

    return <>
        <Stack direction="column">
            <Typography fontSize={12} color={inputLabelColor} {...labelProps}>
                {label}
            </Typography>
            {isLoading &&
                <Box display="flex" width="100%">
                    <Skeleton width="100%" />
                </Box>
            }
            {(showSkeletonWhenEmpty !== undefined && (text === null || text === "")) &&
                <Typography>
                    <Skeleton width="100%" animation={false} />
                </Typography>
            }
            {!isLoading && (
                textAction !== undefined ? 
                    <Link component="button" underline="hover" onClick={textAction}>
                        <Typography {...textProps}>
                            {text}
                        </Typography>
                    </Link>
                :
                    <Typography {...textProps}>
                        {text}
                    </Typography>
                )
            }
        </Stack>
    </>;
};

export default LabeledText;