import { useCallback, useMemo, useState } from "react";
import { Container, Box, Grid, Stack, Collapse } from "@mui/material";

import MfgCustomerCard from "components/Orders/OrderView/Header/MfgCustomerCard";

import { useTranslations } from "@fenetech/translations";
import useWindowTitle from "helpers/context/Title/useWindowTitle";
import useWait from "helpers/context/Page/useWait";
import useOrderData from "components/Orders/useOrderData";
import useOrderTypeText from "components/Orders/useOrderTypeText";
import useApplicationInfo from "helpers/context/Application/useApplicationInfo";

import OrdersAPI from "components/Orders/OrdersAPI";
import { OrderTypesEnum } from "helpers/enums";
import MoreLessButton from "components/Common/MoreLessButton";
import OrderInfoCard from "components/Orders/OrderView/Header/OrderInfoCard";
import SalesCard from "components/Orders/OrderView/Header/SalesCard";
import ShippingCard from "components/Orders/OrderView/Header/ShippingCard";
import GeneralCard from "components/Orders/OrderView/Header/GeneralCard";
import BillingCard from "components/Orders/OrderView/Header/BillingCard";
import StatusCard from "components/Orders/OrderView/Header/StatusCard";
import LineItemGrid from "components/Orders/OrderView/LineItems/LineItemGrid";
import FooterCard from "components/Orders/OrderView/Footer/FooterCard";
import SurchargeDialog from "components/Quotes/QuoteEntry/Surcharges/SurchargeDialog";
import TaxesDialog from "components/Quotes/QuoteEntry/Taxes/TaxesDialog";

import { ITaxDetail } from "helpers/interfaces";
import LoadingLinearProgress from "components/Common/LoadingLinearProgress";
import PoliciesChip from "./Header/PoliciesChip";
import PoliciesDialog from "./Policies/PoliciesDialog";
import useOrderActions from "../useOrderActions";
import RelatedLinksDialog from "./Header/RelatedLinksDialog";
import OrderHeaderActions from "./Header/OrderHeaderActions";
import OtherOnlineAckOrders from "./Footer/OtherOnlineAckOrders";
import AcknowledgeOrder from "./Footer/AcknowledgeOrder";

interface IProps {
    oKey: number
}

const OrderView: React.FC<IProps> = ({ oKey }: IProps) => {

    const appInfo = useApplicationInfo();
    const tm = useTranslations();
    const [viewMore, setViewMore] = useState<boolean>(false);
    const [surchargeDialogVisible, setSurchargeDialogVisible] = useState<boolean>(false);
    const [relatedLinksDialogVisible, setRelatedLinksDialogVisible] = useState<boolean>(false);
    const [policiesDialogVisible, setPoliciesDialogVisible] = useState<boolean>(false);
    const [taxesDialogVisible, setTaxesDialogVisible] = useState<boolean>(false);
    const [taxDetails, setTaxDetails] = useState<ITaxDetail[] | undefined>(undefined);
    const { order, lineItems, totals, permissions, policies, isOnContainer, hasBeenReleased } = useOrderData();
    const orderActions = useOrderActions();
    const wait = useWait();
    const orderTypeText = useOrderTypeText();

    const title = useMemo(() => {
        if (order) {
            return `${orderTypeText} - #${order.orderNumber ?? ""}`;
        }
        return "";
    }, [order, orderTypeText]);

    useWindowTitle(title);

    const isPickup = useMemo(() => {
        return order && order.orderType === OrderTypesEnum.Pickup;
    }, [order]);

    const priceVisible = useMemo(() => {
        return !isPickup && (permissions?.viewPrice ?? false);
    }, [permissions, isPickup]);

    const sqFtPriceVisible = useMemo(() => {
        return priceVisible && appInfo.parameters.showSquareFeet;
    }, [priceVisible, appInfo]);

    const costVisible = useMemo(() => {
        return !isPickup && (permissions?.viewPrice ?? false);
    }, [permissions, isPickup]);

    const isPoliciesDialogVisible = useMemo(() => {
        return policiesDialogVisible && (policies?.length ?? 0) > 0;
    }, [policiesDialogVisible, policies]);

    const onSurchargeClick = useCallback(() => {
        setSurchargeDialogVisible(true);
    }, [setSurchargeDialogVisible]);

    const onTaxClick = useCallback(() => {
        if (order?.oKey) {
            wait.Show(true);
            OrdersAPI.GetTaxDetails(order.oKey).then((td) => {
                wait.Show(false);
                setTaxDetails(td);
                setTaxesDialogVisible(true);
            });
        }
    }, [order, wait]);

    const onApprovePolicies = useCallback(async () => {
        if (order?.oKey) {
            wait.Show(true);
            const baseContent = await orderActions.ApproveOrderPoliciesAsync(order.oKey);
            await orderActions.LoadOrderPoliciesAsync(order.oKey);
            wait.Show(false);
            return tm.Get(baseContent);
        }
        return "";
    }, [order, wait, orderActions, tm]);

    const orderHasProductionStatus: boolean = useMemo(() => {
        return (isOnContainer !== undefined && isOnContainer) || (hasBeenReleased !== undefined && hasBeenReleased);
    }, [isOnContainer, hasBeenReleased]);

    const orderHasRelatedQuote: boolean = useMemo(() => {
        if (order) {
            if (order.remoteOrderNumber && order.remoteOKey)
                return true;
        }
        return false;
    }, [order]);

    if (!order) {
        return <LoadingLinearProgress />;
    }

    return <>

        <Container maxWidth="xl" >
            <Stack direction="column" gap={1} mt={1}>
                <Grid container direction={{ xs: "column-reverse", sm: "row" }} gap={1}>
                    <Grid item xs display="flex">
                        <Box display="flex" flexDirection="row" gap={1} flexWrap="wrap">
                            <MfgCustomerCard />
                        </Box>
                    </Grid>
                    <Grid item display="flex" alignSelf="flex-start">
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <Stack direction="row" spacing={1} justifyContent={{ xs: "flex-start", sm: "flex-end" }}>
                                    <OrderHeaderActions
                                        order={order}
                                        orderHasRelatedLinks={orderHasProductionStatus || orderHasRelatedQuote}
                                        setRelatedLinksDialogOpen={setRelatedLinksDialogVisible}
                                    />
                                </Stack>
                            </Grid>
                            <Grid item xs={12} display="flex" justifyContent={{ xs: "flex-start", sm: "flex-end" }}>
                                <PoliciesChip
                                    onClick={() => setPoliciesDialogVisible(true)}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <StatusCard
                    isPickup={isPickup}
                    costVisible={costVisible}
                />
                <Box display="flex">
                    <MoreLessButton moreText={tm.Get("More")} lessText={tm.Get("Less")} viewMore={viewMore} setViewMore={setViewMore} />
                </Box>
                <Collapse in={viewMore}>
                    <Grid container display="flex" direction="row" spacing={1} >
                        <Grid item xs={12} sm={6} md display="flex">
                            <OrderInfoCard orderTypeText={orderTypeText} />
                        </Grid>
                        <Grid item xs={12} sm={6} md display="flex">
                            <SalesCard />
                        </Grid>
                        {!isPickup &&
                            <Grid item xs={12} sm={6} md display="flex">
                                <BillingCard />
                            </Grid>
                        }
                        <Grid item xs={12} sm={6} md display="flex">
                            <ShippingCard />
                        </Grid>
                        <Grid item xs={12} sm={6} md display="flex">
                            <GeneralCard />
                        </Grid>
                    </Grid>
                </Collapse >
                {totals &&
                    <LineItemGrid
                        tm={tm}
                        priceVisible={priceVisible}
                        sqFtPriceVisible={sqFtPriceVisible}
                        isPickup={isPickup}
                        onSurchargeClick={onSurchargeClick}
                        onTaxClick={onTaxClick}
                    />
                }

                <Box>
                </Box>
                {totals &&
                    <FooterCard />
                }
                <AcknowledgeOrder />
                <OtherOnlineAckOrders />
            </Stack >

        </Container >

        <SurchargeDialog
            dialogVisible={surchargeDialogVisible}
            isReadOnly={true}
            viewCost={false}
            lineItems={lineItems}
            onCancel={() => setSurchargeDialogVisible(false)}
            onSubmit={() => { }}
        />

        <RelatedLinksDialog
            hasProductionStatus={orderHasProductionStatus}
            hasRelatedQuote={orderHasRelatedQuote}
            isOpen={relatedLinksDialogVisible}
            onClose={() => setRelatedLinksDialogVisible(false)}
        />

        {taxDetails &&
            <TaxesDialog
                dialogVisible={taxesDialogVisible}
                isReadOnly={true}
                taxDetails={taxDetails}
                onCancel={() => setTaxesDialogVisible(false)}
                onSubmit={() => { }}
            />
        }

        <PoliciesDialog
            dialogVisible={isPoliciesDialogVisible}
            onClose={() => setPoliciesDialogVisible(false)}
            onApprove={onApprovePolicies}
        />

    </>
};

export default OrderView;

