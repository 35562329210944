export enum AuthorizationTypeEnum {
    Dealer,
    CSR
}

export enum LoginAuthenticationTypeEnum {
    Standard = 0,
    SingleSignOn = 1,
}

export enum RoleEnum {
    Dealer = 1,
    CSR = 2,
    Contractor = 3,
    OnlineAck = 4,
}

export enum UserPermissionTypes {
    UploadQuotes = 1, //Doesn't apply to extended contractors
    UploadQuotesWithExceptions = 2, //Doesn't apply to extended contractors
    CreateModifyQuotes = 4,
    ModifyQuotePricing = 8,
    DeleteQuotes = 16,
    ViewPricing = 32,
    ViewCost = 64,
    CreateModifyCustomersAndPricing = 128,
    ViewQuotesCompany = 256,
    ViewQuotesCustomer = 512,
    SubmitQuotes = 1024, // 'Doesn't apply to dealers
    ModifyQuoteTaxes = 2048,
    ApproveWebHolds = 4096, // 'Doesn't apply to extended contractors
    ViewQuoteMarkupMarginDiscount = 8192
}

export enum CompanyPermissionEnum {
    UploadQuotes = 1, //WC01 in AppPermissions
    UploadQuotesWithExceptions = 2, //WC02
    DropShip = 4, //WC03
}

export enum ThemeColorEnum {
    Primary = "primary",
    Secondary = "secondary",
    Success = "success",
    Error = "error",
    Info = "info",
    Warning = "warning"
}


export enum OrderTypesEnum {
    Quote = 0,
    Order,
    Credit,
    InvoiceOnly,
    Manufacturing,
    Forecast,
    Pickup
}

export enum ClassificationTypeEnum {
    Recent = -6,
    Uploaded = -5,
    Submitted = -4,
    Expired = -3,
    OnHold = -2,
    Available = -1,
    OnHoldBeforeUpload = 1,
    AfterUpload = 2,
    AvailableBeforeUpload = 3    
}

export enum OrderStatusEnum {
    OnHold = 0,
    Available = 1,
    Finalized = 2,
    Released = 3,
    Uploaded = 100
}

export enum PricingMethodEnum {
    Markup = 0,
    Discount = 1,
    Margin = 2,
    Markdown = 3
}

export enum ControlTypeEnum {
    Checkbox = 0,
    Textbox = 1,
    Combobox = 2
}

export enum OpeningShapeEnum {
    Standard = 0,
    Rake = 1,
    Gable = 2,
    Arc = 3,
    HalfArc = 4
}

export enum SizingModeEnum {
    Fixed = 0,
    Grow = 1
}

export enum ShapeDirectionEnum {
    Left = 0,
    Right = 1
}

export enum DisplacementDirectionEnum {
    None = 0,
    Left = 1,
    Right = 2,
    Top = 4,
    Bottom = 8,
    Both = 16,
    Opening = 32
}

export enum ExportTypeEnum
{
    Excel = 1,
    PDF,
    Image,
    Word,
}

export enum LoginTypeEnum {
    AllSites = 0,
    SingleSite = 1,
    MultipleSites = 2
}

export enum SecurityLevelEnum {
    Standard = 0,
    Administrator = 255
}

export enum ProdStatusTypeEnum {
    Complete = 0,
    Rejected = 1,
    Released = 2
}

export enum MaintenanceFilterEnum {
    All = 0,
    Active = 1,
    Inactive = 2
}

export enum PONumberOptionEnum {
    Default = 0,
    Order = 1,
    PO = 2,
    Blank = 3
}

export enum CustomerRefOptionEnum {
    Default = 0,
    Customer = 1,
    OrderCustomer = 2,
    CustomerRef = 3,
    Blank = 4
}

export enum InvoiceTypeEnum {
    Standard = 1,
    Deposit = 3,
    Transfer = 4,
    Cancellation = 5,
    CreditNote = 6,
    DepositCancellation = 7,
    CreditNoteCancellation = 8,
}