import { Stack } from "@mui/material";
import { IInvoice } from "helpers/interfaces";
import Invoice from "./Invoice";

interface IProps {
    invoices: IInvoice[]
}

function InvoiceWrapper({ invoices }: IProps) {
    return <Stack direction="column" spacing={1}>
        {invoices.map(i => <Invoice key={i.invoiceNumber} invoice={i} />)}
    </Stack>
}

export default InvoiceWrapper;